<template>
  <body>
    <div class="banner">
      <img src="@/assets/banner.svg" />
    </div>
    <div class="linea1">
      <img src="@/assets/linea1.svg" />
    </div>
    <div class="header">
      <div class="header__item"><img src="@/assets/texto1.svg" /></div>
      <div class="header__item"><img src="@/assets/mapa.svg" /></div>
    </div>
    <div class="video">
      <a
        href="https://www.youtube.com/watch?v=-l9CxotP3_Y"
        target="_blank"
        rel="noopener noreferrer"
        ><img src="@/assets/video.png"
      /></a>
    </div>
    <div class="titulo">
      <img src="@/assets/titulo2.svg" />
    </div>
    <div class="texto2">
      <img src="@/assets/texto2.svg" />
    </div>
    <div class="imagen">
      <img src="@/assets/imagen2.png" />
    </div>
    <div class="titulo">
      <img src="@/assets/titulo3.svg" />
    </div>
    <div class="imagen">
      <img src="@/assets/imagen3a.png" />
    </div>
    <div class="imagen">
      <img src="@/assets/imagen3b.png" />
    </div>
    <div class="botones">
      <br /><br /><br /><br /><br />
      <a
        href="https://paisaje-de-conservacion-nahuelbuta-norte-pctn.hub.arcgis.com/"
        target="_blank"
        rel="noopener noreferrer"
        class="button"
        ><img src="@/assets/boton1.svg" /></a
      ><br />
      <a
        href="https://drive.google.com/file/d/1QE8ER0IvLreq1zKLBiM0g_7d_tfsajh1/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
        class="button"
        ><img src="@/assets/boton2.svg" /></a
      ><br />
      <a
        href="https://forms.gle/65GBU8tBQXnrPFdH7"
        target="_blank"
        rel="noopener noreferrer"
        class="button"
        ><img src="@/assets/boton3.svg"
      /></a>
    </div>
  </body>

  <!-- <div class="footer">
    <img src="@/assets/footer.png" />
  </div> -->
</template>

<script>
export default {
  name: "HomePage",
};
</script>

<style scoped>
body {
  background-image: url("@/assets/background-image.svg");
  background: cover;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  height: 7682px;
  width: vw;
}

.banner img {
  width: 550px;
  /* height: 400px; */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.linea1 img {
  /* width: 398px;
  height: 316px; */
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.titulo {
  margin-top: 150px;
}

.texto2 img {
  width: 80vw;
  /* height: 316px; */
  /* display: block;
  margin-left: auto;
  margin-right: auto; */
}

.imagen img {
  width: 90vw;
  margin-top: 100px;
}

.header {
  display: flex;
  margin: 0 0 30px 0;
}

.header__item {
  flex: 1;
  z-index: 1000;
}

.video iframe {
  width: 80vw;
  height: 600px;
}

.button {
  border: none;
  cursor: pointer;
  appearance: none;
  background-color: inherit;
  transition: transform 0.7s ease-in-out;
  margin-left: 10px;
}
/* button:hover {
  transform: rotate(360deg);
} */
.button img {
  width: 30vw;
}
.footer img {
  width: 90vw;
  position: absolute;
  bottom: 0;
}
</style>
